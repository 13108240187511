import React, { useEffect, useState } from "react";
import { SvgCancel } from "../../svgIcons/Icons";
import { PrimaryInput } from "../NewCustomComp/Inputs/Inputs";
import {
  PrimaryButton,
  SecondaryButton,
} from "../NewCustomComp/Buttons/Buttons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { json } from "react-router-dom";
import { SelectBasicWithSelectTag } from "../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import UploadFileComponent from "../NewCustomComp/UploadFileComponent/UploadFileComponent";

import { getDecryptedData } from "../../../assets/encryptStorage";

const EditChannelPopup = ({
  addChannelData,
  setAddChannelData,
  fetchInfo,
  isEdit,
  show,
  setShow,
}) => {
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setAddChannelData({
      ...addChannelData,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    try {
      const updateResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/channel/update/${addChannelData.id}`,
        {
          method: "POST",
          body: JSON.stringify(addChannelData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const updateData = await updateResponse.json();

      if (updateData.success) {
        showSuccessToast("Channel Updated Successfully");

        // If there’s a logo to update, await `addChannelLogo`
        if (logo) {
          const logoUpdateSuccess = await addChannelLogo();
          if (logoUpdateSuccess) {
            fetchInfo(); // Now fetch the updated list, including the logo, only if logo update succeeded
          } else {
            showErrorToast("Logo update failed. Please try again.");
          }
        } else {
          fetchInfo(); // If there's no logo to update, proceed with fetching info directly
        }

        setShow(false);
      } else {
        showErrorToast(updateData.message);
      }
    } catch (error) {
      console.error("Update failed:", error);
      showErrorToast("Something went wrong. Please try again.");
    }
  };

  const handleCreateChannel = async () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/channel/create`, {
      method: "POST",
      body: JSON.stringify(addChannelData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast("Channel Updated Successfully");
          setShow(false);
          fetchInfo();
        } else {
          showErrorToast(data.message);
        }
      });
  };

  //   property logo upload

  const [logo, setLogo] = useState(false);
  const [logoId, setLogoId] = useState("");
  const handleFileChange = (data, name) => {
    setLogo(data);
  };
  const addChannelLogo = async () => {
    const formData = new FormData();
    formData.append("associated_id", addChannelData.id);
    formData.append("channel_logo", logo); // Make sure `logo` is a `File` or `Blob` object here

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/media/store/channel/logo`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        showSuccessToast(data.message);
      } else {
        showErrorToast(
          "Something went wrong while updating the logo. Please try again."
        );
      }

      return data.success; // return true if successful, so we can check it
    } catch (error) {
      console.error("Logo update error:", error);
      showErrorToast("Failed to upload logo. Please check your network.");
      return false; // return false in case of error
    }
  };

  const getChannelLogo = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/fetch/channel/logo/${addChannelData.id}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        if (data.channel_logo && data.channel_logo.src) {
          setLogo(data.channel_logo.src);
          setLogoId(data.channel_logo.id);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        showErrorToast("Failed to fetch logo. Please check your network.");
      });
  };
  useEffect(() => {
    if (addChannelData?.id) {
      getChannelLogo();
    }
  }, [addChannelData?.id]);

  const handleDeleteLogo = () => {
    if (logoId) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/media/delete/channel/logo/${logoId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          if (data.channel_logo && data.channel_logo.src) {
            setLogo("");
            setLogoId("");
          }
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          showErrorToast("Failed to fetch logo. Please check your network.");
        });
    }
  };

  return (
    <div>
      <div className="flex align_items_center justify_content_between">
        <div className="add-ota-model-container-heading">
          {isEdit ? "Edit Channel" : "Add Channel"}
        </div>
        <span style={{ cursor: "pointer" }} onClick={() => setShow(false)}>
          <SvgCancel />
        </span>
      </div>

      <hr className="mt-10 mb-10" />

      <div>
        <div style={{ fontWeight: "600" }}>Active Environment</div>

        <div className="flex flex_gap_10">
          <label className="flex flex_gap_5 pointer">
            <input
              type="radio"
              className="pointer"
              value="dev"
              name="active_environment"
              checked={addChannelData.active_environment === "dev"}
              onChange={(e) =>
                setAddChannelData({
                  ...addChannelData,
                  active_environment: e.target.value,
                })
              }
            />
            Development
          </label>

          <label className="flex flex_gap_5 pointer">
            <input
              type="radio"
              value="live"
              className="pointer"
              name="active_environment"
              checked={addChannelData.active_environment === "live"}
              onChange={(e) =>
                setAddChannelData({
                  ...addChannelData,
                  active_environment: e.target.value,
                })
              }
            />
            Production
          </label>
        </div>
      </div>

      {addChannelData.active_environment === "dev" ? (
        <div>
          <div className="flex flex_gap_10 mt-10">
            <PrimaryInput
              type="text"
              label="Channel Name"
              value={addChannelData.channel_name || ""}
              name={"channel_name"}
              onChange={handleEditChange}
              size={"medium"}
              disabled={isEdit ? true : false}
            />
            <PrimaryInput
              type="text"
              label="Slug"
              value={addChannelData.slug || ""}
              onChange={handleEditChange}
              size={"medium"}
              name={"slug"}
            />
            <PrimaryInput
              type="text"
              label="Channel Dev URL"
              value={addChannelData.channel_dev_url || ""}
              onChange={handleEditChange}
              size={"medium"}
              name={"channel_dev_url"}
            />
          </div>
          <div className="flex flex_gap_10  mt-10">
            <PrimaryInput
              type="text"
              label="Dev URL"
              value={addChannelData.our_dev_url || ""}
              name={"our_dev_url"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              type="text"
              label="Dev Secret Key"
              value={addChannelData.channel_dev_secret_key || ""}
              name={"channel_dev_secret_key"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              type="text"
              label="Our Dev Api Key"
              value={addChannelData.our_dev_api_key || ""}
              name={"our_dev_api_key"}
              onChange={handleEditChange}
              size={"medium"}
            />
          </div>

          <div className="flex flex_gap_10 mt-10">
            <PrimaryInput
              label={"Channel Dev User-Name"}
              value={addChannelData.channel_dev_username || ""}
              name={"channel_dev_username"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              label={"Our Dev User-Name"}
              value={addChannelData.our_dev_username || ""}
              name={"our_dev_username"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <SelectBasicWithSelectTag
              style={{ width: "200px" }}
              label={"Our Authorization Type"}
              value={addChannelData.our_authorization_type || ""}
              name={"our_authorization_type"}
              onChange={handleEditChange}
              options={[
                { label : "select" , value : ""},
                { label: "api_key", value: "api_key" },
                {
                  label: "basic_auth",
                  value: "basic_auth",
                },
              ]}
              size={"medium"}
            />
          </div>

          <div className="flex flex_gap_10 mt-10">
            <PrimaryInput
              label={"Channel Dev Password"}
              value={addChannelData.channel_dev_password || ""}
              name={"channel_dev_password"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              label={"Our Dev Password"}
              value={addChannelData.our_dev_password || ""}
              name={"our_dev_password"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <SelectBasicWithSelectTag
              style={{ width: "200px" }}
              label={"Channel Authorization Type"}
              value={addChannelData.channel_authorization_type || ""}
              name={"channel_authorization_type"}
              onChange={handleEditChange}
              options={[
                { label : "select" , value : ""},
                { label: "api_key", value: "api_key" },
                {
                  label: "basic_auth",
                  value: "basic_auth",
                },
              ]}
              size={"medium"}
            />
          </div>
          <div className="flex flex_gap_10 mt-10">
            <PrimaryInput
              label={"Channel Dev Requestor ID"}
              value={addChannelData.channel_dev_requestor_id || ""}
              name={"channel_dev_requestor_id"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              label={"Channel Dev Token"}
              value={addChannelData.channel_dev_token || ""}
              name={"channel_dev_token"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              label={"Channel Dev Bearer Token"}
              value={addChannelData.channel_dev_bearer_token || ""}
              name={"channel_dev_bearer_token"}
              onChange={handleEditChange}
              size={"medium"}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex_gap_10 mt-10">
            <PrimaryInput
              type="text"
              label="Channel Name"
              value={addChannelData.channel_name || ""}
              name={"channel_name"}
              onChange={handleEditChange}
              size={"medium"}
              disabled={isEdit ? true : false}
            />
            <PrimaryInput
              type="text"
              label="Slug"
              name={"slug"}
              value={addChannelData.slug || ""}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              type="text"
              name={"channel_live_url"}
              label="Channel Live URL"
              value={addChannelData.channel_live_url || ""}
              onChange={handleEditChange}
              size={"medium"}
            />
          </div>
          <div className="flex flex_gap_10 mt-10 ">
            <PrimaryInput
              type="text"
              label="Live URL"
              value={addChannelData.our_live_url || ""}
              name={"our_live_url"}
              onChange={handleEditChange}
              size={"medium"}
            />

            <PrimaryInput
              type="text"
              label="Channel Live Secret Key"
              value={addChannelData.channel_live_secret_key || ""}
              name={"channel_live_secret_key"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              type="text"
              label="Our Live Api Key"
              value={addChannelData.our_live_api_key || ""}
              name={"our_live_api_key"}
              onChange={handleEditChange}
              size={"medium"}
            />
          </div>

          <div className="flex flex_gap_10 mt-10">
            <PrimaryInput
              label={"Channel Live User-Name"}
              value={addChannelData.channel_live_username || ""}
              name={"channel_dev_username"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              label={"Our Live User-Name"}
              value={addChannelData.our_live_username || ""}
              name={"our_dev_username"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <SelectBasicWithSelectTag
              style={{ width: "200px" }}
              label={"Our Authorization Type"}
              value={addChannelData.our_authorization_type || ""}
              name={"our_authorization_type"}
              onChange={handleEditChange}
              options={[
                { label : "select" , value : ""},
                { label: "api_key", value: "api_key" },
                {
                  label: "basic_auth",
                  value: "basic_auth",
                },
              ]}
              size={"medium"}
            />
          </div>

          <div className="flex flex_gap_10 mt-10">
            <PrimaryInput
              label={"Channel Live Password"}
              value={addChannelData.channel_live_password || ""}
              name={"channel_live_password"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              label={"Our Live Password"}
              value={addChannelData.our_live_password || ""}
              name={"our_live_password"}
              onChange={handleEditChange}
              size={"medium"}
            />

            <SelectBasicWithSelectTag
              style={{ width: "200px" }}
              label={"Channel Authorization Type"}
              value={addChannelData.channel_authorization_type || ""}
              name={"channel_authorization_type"}
              onChange={handleEditChange}
              options={[
                { label : "select" , value : ""},
                { label: "api_key", value: "api_key" },
                {
                  label: "basic_auth",
                  value: "basic_auth",
                },
              ]}
              size={"medium"}
            />
          </div>
          <div className="flex flex_gap_10 mt-10">
            <PrimaryInput
              label={"Channel Live Requestor ID"}
              value={addChannelData.channel_live_requestor_id || ""}
              name={"channel_live_requestor_id"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              label={"Channel Live Token"}
              value={addChannelData.channel_live_token || ""}
              name={"channel_live_token"}
              onChange={handleEditChange}
              size={"medium"}
            />
            <PrimaryInput
              label={"Channel Live Bearer Token"}
              value={addChannelData.channel_live_bearer_token || ""}
              name={"channel_live_bearer_token"}
              onChange={handleEditChange}
              size={"medium"}
            />
          </div>
        </div>
      )}
      {isEdit ? (
        <div>
          <div className="mt-20">Channel Logo</div>
          <div
            className="mt-10 flex flex_gap_10 align_items_center"
            style={{ width: "100%" }}
          >
            <UploadFileComponent
              defaultFile={logo}
              onFileChange={handleFileChange}
              width={"100%"}
              url={true}
              deleteImageApiCall={handleDeleteLogo}
            />

            {/* <PrimaryButton
              title={"upload"}
              onClick={addChannelLogo}
              size={"medium"}
            /> */}
          </div>
        </div>
      ) : null}

      {/* <PrimaryButton
        title={"upload"}
        onClick={addChannelLogo}
        size={"medium"}
      /> */}

      <div className="flex flex_gap_10 mt-10">
        <PrimaryButton
          size={"medium"}
          title={isEdit ? "Update" : "Add"}
          onClick={isEdit ? handleUpdate : handleCreateChannel}
        />
        <SecondaryButton
          size={"medium"}
          title={"Cancel"}
          onClick={() => setShow(false)}
        />
      </div>
    </div>
  );
};

export default EditChannelPopup;
