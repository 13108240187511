import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
} from "@shopify/polaris";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link, useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { useSelector } from "react-redux";
import Loader from "../Components/UI/Loader/Loader";
import AddCsvFile from "./UI/AddCsvFile";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import NoDataFound from "./CustomComponents/NoDataFound copy";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "./CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import {
  PrimaryButton,
  SecondaryButton,
} from "./CustomComponents/NewCustomComp/Buttons/Buttons";
import { SvgDelete, SvgEdit } from "../assets/svgIcons";
import ConfirmPopup from "./CustomComponents/NewCustomComp/confirmPopup/ConfirmPopup";
import { PrimaryInput } from "./CustomComponents/NewCustomComp/Inputs/Inputs";
import { getDecryptedData } from "../assets/encryptStorage";
const Competitors = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [showAddRatesMenuActive, setShowAddRatesMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");
  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );

  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  const [orders, setOrders] = useState([]);

  // Fetch Info
  const property_id = useSelector((state) => state.property.value);

  const [loader, setLoader] = useState(true);

  const fetchInfo = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/competitors/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "data");
      setOrders(data.data.data);
      // console.log(data.rooms);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const addModalRatesButtonClickHandler = () => {
    setShowAddRatesMenuActive(!showAddRatesMenuActive);
    // setShowLowerMenuSingleSelect(false);
    // setShowLowerMenuMultipleSelect(false);(!showLowerMenuAddMenu);
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  // getItemChecked(selectedResources[0]);
  const [competitorId, setCompetitorId] = useState("");

  useEffect(() => {
    setCompetitorId(competitorId);
  }, [competitorId]);

  const addCompetitorsRatesHandler = (id) => {
    setCompetitorId(id);
    setShowAddRatesMenuActive(true);
    console.log(id);
  };

  // UseState for forms
  const [addRatesData, setAddRateData] = useState({
    rate_date: "",
    rate: "",

    competitor_id: competitorId,
  });

  const setAddItemRateDataHandleChange = (event) => {
    setAddRateData({
      ...addRatesData,
      [event.target.name]: event.target.value,
    });
  };

  const navigate = useNavigate();

  const rowMarkup = orders?.map(
    ({
      id,
      destination,
      competitor_name,
      hotel_category,
      manageButton,
      index,
    }) => (
      <CustomShopifyIndexTableRow id={id} key={id}>
        <CustomShopifyIndexTableCell>
          {<CustomCssComponentTableText innerText={competitor_name} />}
        </CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell>
          {<CustomCssComponentTableText innerText={destination} />}
        </CustomShopifyIndexTableCell>

        <CustomShopifyIndexTableCell>
          <div className="flex flex_gap_10 align_items_center">
            <PrimaryButton
              size={"small"}
              title={"Manage Rates"}
              onClick={() => navigate(`/ratescalender/${id}`)}
            />

            <span onClick={() => handleEditClick(id)}>
              <SvgEdit />
            </span>
            <span onClick={() => setDeletedId(id)}>
              <SvgDelete />
            </span>
          </div>
        </CustomShopifyIndexTableCell>
      </CustomShopifyIndexTableRow>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  

 

  const [hotelCategoryTypeSelected, setHotelCategoryPlanTypeSelected] =
    useState("EP");
  const hotelCategoryTypeHandleSelectChange = useCallback(
    (value) => setHotelCategoryPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  
 



 
  // UseState for forms
  const [competitorsData, setCompetitorsData] = useState({
    average_rate: "",
    competitor_name: "",
    hotel_category: "",
    // status: "booked",

    property_id: property_id?.id,
  });



  const [deletedId, setDeletedId] = useState(false);
  const deleteData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/delete-competitor/${deletedId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          // body: JSON.stringify(deletedIds),
        }
      );

      const data = await response.json();

      if (data.success === true) {
        setDeletedId(false);
        fetchInfo();

        showSuccessToast("Data deleted successfully:", data);
      }
    } catch (error) {
      // Handle the error
      console.error("Failed to delete data:", error.message);
      showErrorToast(
        "An error occurred while deleting the data. Please try again."
      );
    }
  };

  const [activeStep, setActiveStep] = useState("");
  const [singleCompetitorData, setSingleCompetitorData] = useState({
    competitor_name: "",
    destination: "",
  });

  const handleAddCompetitor = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/create-competitor`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(singleCompetitorData),
        }
      );

      // Check for response status
      if (!response.ok) {
        const errorData = await response.json(); // Extract error details
        throw new Error(errorData.message || "Failed to create competitor");
      }

      const data = await response.json();
      showSuccessToast("Competitor added successfully:", data);
      fetchInfo();
      handlePopupClose();
    } catch (error) {
      console.error("Error adding competitor:", error.message);
      showErrorToast(
        "An error occurred while adding the competitor. Please try again."
      );
    }
  };

  const handlePopupClose = () => {
    setActiveStep("");
    setShowAddModelMenuActive(false);
    setEditId(false);
    setSingleCompetitorData({ competitor_name: "", destination: "" });
  };

  const [editId, setEditId] = useState(false);

  const handleEditClick = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/competitor-details/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      // Check for response status
      if (!response.ok) {
        const errorData = await response.json(); // Extract error details
        throw new Error(errorData.message || "Failed to create competitor");
      }

      const data = await response.json();
      setEditId(id);
      setSingleCompetitorData({
        competitor_name: data.data.competitor_name,
        destination: data.data.destination,
      });
    } catch (error) {
      console.error("Error adding competitor:", error.message);
    }
  };

  const handleUpdateCompetitor = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/update-competitor/${editId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(singleCompetitorData),
        }
      );

      // Check for response status
      if (!response.ok) {
        const errorData = await response.json(); // Extract error details
        throw new Error(errorData.message || "Failed to create competitor");
      }

      const data = await response.json();
      showSuccessToast("Competitor added successfully:", data);
      fetchInfo();
      handlePopupClose();
    } catch (error) {
      console.error("Error adding competitor:", error.message);
      showErrorToast(
        "An error occurred while adding the competitor. Please try again."
      );
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Competitors</div>
              <div>
                <BgThemeButton
                  onClick={addModalButtonClickHandler}
                  children={"Add Competitors"}
                />
              </div>
            </div>
            {orders && orders?.length > 0 ? (
              <LegacyCard>
                <CustomShopifyIndexTable
                  showCheckbox={false}
                  headings={["Name", "Destination", ""]}
                >
                  {rowMarkup}
                </CustomShopifyIndexTable>

                {/* Single select */}
                {showLowerMenuSingleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          gap: "20px",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                        {/* <Link
                    style={{ textDecoration: "none" }}
                    to={`/competitors/manage`}
                  >
                    <BgOutlineThemeButton children={"Manage"} />
                  </Link> */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Multiple select */}
                {showLowerMenuMultipleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </LegacyCard>
            ) : (
              <div>
                <NoDataFound />
              </div>
            )}
          </div>

          {deletedId && (
            <ConfirmPopup
              heading={"Delete Competitor"}
              subHeading={"Are you sure you want to delete this competitor?"}
              setShowPopup={setDeletedId}
              handleConfirmClick={deleteData}
            />
          )}

          {/* Add Button Click */}
          {showAddModelMenuActive &&
            (!activeStep ? (
              <div className="bg-backdrop">
                <div
                  className="room-plan-add-model-container"
                  style={{ width: "fit-content" }}
                >
                  <div className="flex flex_gap_10">
                    <PrimaryButton
                      title={"Add Competitor"}
                      size={"medium"}
                      onClick={() => setActiveStep("add")}
                    />
                    <PrimaryButton
                      title={"Import Competitor"}
                      size={"medium"}
                      onClick={() => setActiveStep("import")}
                    />
                  </div>
                </div>
              </div>
            ) : activeStep === "import" ? (
              <AddCsvFile
                showAddModelMenuActive={showAddModelMenuActive}
                setShowAddModelMenuActive={handlePopupClose}
                title={"Import Competitors"}
              />
            ) : activeStep === "add" ? (
              <div className="bg-backdrop">
                <div className="room-plan-add-model-container">
                  <h4>Add Competitor</h4>

                  <div>
                    <div className="flex flex_gap_10 mb-10">
                      <div className="w-50">
                        <PrimaryInput
                          size={"medium"}
                          label={"Name"}
                          value={singleCompetitorData.competitor_name}
                          onChange={(e) =>
                            setSingleCompetitorData({
                              ...singleCompetitorData,
                              competitor_name: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="w-50">
                        <PrimaryInput
                          size={"medium"}
                          label={"Destination"}
                          value={singleCompetitorData.destination}
                          onChange={(e) =>
                            setSingleCompetitorData({
                              ...singleCompetitorData,
                              destination: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex_gap_10">
                      <PrimaryButton
                        type="submit"
                        size="medium"
                        title={"Add"}
                        onClick={handleAddCompetitor}
                      />
                      <SecondaryButton
                        size="medium"
                        title={"Cancel"}
                        onClick={handlePopupClose}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null)}
 

           {/*  edit update */}
          {editId && (
            <div className="bg-backdrop">
              <div className="room-plan-add-model-container">
                <h4>Add Competitor</h4>

                <div>
                  <div className="flex flex_gap_10 mb-10">
                    <div className="w-50">
                      <PrimaryInput
                        size={"medium"}
                        label={"Name"}
                        value={singleCompetitorData.competitor_name}
                        onChange={(e) =>
                          setSingleCompetitorData({
                            ...singleCompetitorData,
                            competitor_name: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="w-50">
                      <PrimaryInput
                        size={"medium"}
                        label={"Destination"}
                        value={singleCompetitorData.destination}
                        onChange={(e) =>
                          setSingleCompetitorData({
                            ...singleCompetitorData,
                            destination: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="flex flex_gap_10">
                    <PrimaryButton
                      type="submit"
                      size="medium"
                      title={"Update"}
                      onClick={handleUpdateCompetitor}
                    />
                    <SecondaryButton
                      size="medium"
                      title={"Cancel"}
                      onClick={handlePopupClose}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          

          
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Competitors;
